
import { Options, Vue } from 'vue-class-component';
import { apiReaderGetList,
         apiReaderGetListTotalNum } from '../services/tpsApi/tpsApiReader';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '../services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '../services/exportFile';
import { IReaderList } from '../services/tpsApi/models/reader';
import ReportReaderVerSearchMenu from '@/components/ReportReaderVerSearchMenu.vue';
import Table from '@/components/Table.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    ReportReaderVerSearchMenu,
    Table,
    Pagination
  },
})
export default class ReportSearch extends Vue {
    TABLE_HEAD: {} = {
        "品牌名稱": "品牌名稱",
        "卡機內碼": "卡機內碼",
        "卡機號碼": "卡機號碼",
        "車號": "車號",
        "營運參數": "營運參數",
        "付款參數": "付款參數",
        "程式版本": "程式版本",
        "韌體版本": "韌體版本",
        "最後更新時間": "最後更新時間"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    reportList: IReaderList | Blob = [];
    REPORT = "version"; //report="version"，表示卡機版本查詢報表
    pageNo: {} = PAGENO;
    searchQuery: {} = {};
    
    mounted() {
        if(this.$store.getters.permission !== "admin") {
            this.searchQuery["filter_brand"] = this.$store.getters.brand_id;
        }
        this.refreshTabel({report: this.REPORT, limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        let query = Object.assign({report: this.REPORT}, this.searchQuery);
        this.pageTotalNum = await apiReaderGetListTotalNum(query);
    }

    async refreshTabel(pData: {report: string, limit: number, page: number }) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        if(pData && !pData.report) pData.report = this.REPORT;
        pData = Object.assign(pData, this.searchQuery);
        const res = await apiReaderGetList(pData);
        this.reportList = res;
    }

    async download(pData: { output: string }) {
        const res = await apiReaderGetList(Object.assign({  report: this.REPORT,
                                                            output: pData.output },
                                                            this.searchQuery ));
        exportFile({ fileName: "卡機版本查詢報表", data: res });
    }

    doSearch(pData) {
        this.searchQuery = Object.assign({}, pData);
        this.initPagination();
        this.refreshTabel({report: this.REPORT, limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE})
    }
}
