
import { Options, Vue } from 'vue-class-component';
import { Emit } from "vue-property-decorator";
import { apiListMenuGetItem } from '../services/tpsApi/tpsApiListMenuItem';
import { apiReaderGetList } from '../services/tpsApi/tpsApiReader';
import { apiCompanyGetListName } from '../services/tpsApi/tpsApiCompany';
import { apiReaderGetListReaderSN } from '../services/tpsApi/tpsApiReader';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
@Options({
  components: {
    
  },
  emits: [
      "search"
  ]
})


export default class ReportReaderVerSearchMenu extends Vue {
    formData: {} = {};
    paramverSelectOptions: String[] = [];
    //brandSearchDisable: boolean = true;
    brandSelectOptions: {} = {};

    async mounted() {
        //Select options setting
        // if(this.$store.getters.permission == "admin") {
        //     this.brandSearchDisable = false;
        // }
        this.formData["brand_id"] = this.$store.getters.brand_id;
        this.brandSelectOptions = await apiBrandGetListName();
    }

    @Emit("search")
    search() {
        let data = {};
        data['filter_brand'] = this.formData['brand_id'];
        data['filter_readerid'] = this.formData['readerNumber'];
        data['filter_paramversion'] = this.formData['loacl_payment_version'];
        data['filter_plate'] = this.formData['plate_no'];
        
        return data;
    }
}
