import tpsApiInstance from "./tpsApiBase";
import {
  IReaderList,
  IReaderDetail,
  IapiReaderGetListTotalNumRequestParams,
  IReaderGetListRequestParams,
  IReaderGetDetailRequestParams,
  IReaderDetailUpdateRequestParams,
  IReaderCreateResponseSchema,
  IReaderDetailUpdateResponseSchema
} from './models/reader';
import Store from "../../store";
import { IReaderVersionCreateRequestParams } from "./models/reader/IReaderRequestParams";
import { IReaderVersionCreateResponseSchema } from "./models/reader/IReaderResponseSchema";

const READER_PATH = "/reader";

export const apiReaderGetListTotalNum = async(
  pData?: IapiReaderGetListTotalNumRequestParams): Promise<number> => {
  let query: string = "?count=total";
  query += convertQueryValue(pData);
  console.log("query:",query)
  const res = await tpsApiInstance.get(`${READER_PATH}${query}`);
  console.log("total:",res.data[0].total);
  return res?.data[0]?.total;
};

export const apiReaderGetList = async(
  pData?: IReaderGetListRequestParams): Promise<IReaderList | Blob> => {
  let query: string = "?";
  query += convertQueryValue(pData);
  if(pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${READER_PATH}${query}`,{responseType: 'blob'});
    return res?.data as Blob;
  }
  else {
    console.log("query:",query)
    const res = await tpsApiInstance.get(`${READER_PATH}${query}`);
    return res?.data as IReaderList;
  }
};

export const apiReaderGetListReaderSN = async(): Promise<string[]> => {
  const res = await tpsApiInstance.get(READER_PATH);
  var listNum: string[] = [];
  if(res.data) {
    var i;
    for (i = 0; i < res.data.length; i++) {
      listNum.push(res.data[i].reader_sn);
    }
  }
  return listNum;
};
//取沒被使用過的不重複車號
export const apiReaderGetPlateNOList = async(): Promise<{}> => {
  const res = await tpsApiInstance.get(READER_PATH);
  const resDriver = await tpsApiInstance.get("/driver");
  var listPlateNO = {};
  var brand_id = Store.getters.brand_id;
  if(res.data) {
    var i;
    for (i = 0; i < res.data.length; i++) {
        var flag = false
        for (var j = 0; j < resDriver.data.length; j++) {
          if(res.data[i].plate_no == resDriver.data[j].plate_no )
          {
            flag = true
          }
        }
        if(!flag){
          listPlateNO[res.data[i].plate_no] = res.data[i].reader_sn;
        }
    }
  }
  listPlateNO['-']= ''
  //Sort plate_no
  var listPlateNOSorted = Object.keys(listPlateNO).sort().reduce(
    (obj, key) => { 
      obj[key] = listPlateNO[key]; 
      return obj;
    }, 
    {}
  );
  return listPlateNOSorted;
};

export const apiReaderGetDetail = async (
  pData: IReaderGetDetailRequestParams): Promise<IReaderDetail> => {
  const res = await tpsApiInstance.get(`${READER_PATH}/${pData.id}`);
  //console.log(res.data[0]);
  return res?.data as IReaderDetail;
};

export const apiReaderCreate = async (
  pData: IReaderDetail): Promise<IReaderCreateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(READER_PATH, pData);
  return res?.data as IReaderCreateResponseSchema;
};

export const apiReaderDetailUpdate = async (
  pData: {id: number, data: IReaderDetail}): Promise<IReaderDetailUpdateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(`${READER_PATH}/${pData.id}`, pData.data);
  return res?.data as IReaderDetailUpdateResponseSchema;
};

export const apiReaderVersionCreate = async (
  pData: IReaderVersionCreateRequestParams
  ): Promise<IReaderVersionCreateResponseSchema> => {
  console.log("create version:",JSON.stringify(pData));
  const res = await tpsApiInstance.post(`createCompanyRuleVersion`, pData.data);
  console.log(res);
  return res?.data as IReaderVersionCreateResponseSchema;
};

function convertQueryValue(pData): string {
  let query = "";
  if(pData && pData.report) query += `&report=${pData.report}`;
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  if(pData && pData.filter_company) query += `&filter_company=${pData.filter_company}`;
  if(pData && pData.filter_brand) query += `&filter_brand=${pData.filter_brand}`;
  if(pData && pData.filter_readerid) query += `&filter_readerid=${pData.filter_readerid}`;
  if(pData && pData.filter_paramversion) query += `&filter_paramversion=${pData.filter_paramversion}`;
  if(pData && pData.filter_plate) query += `&filter_plate=${pData.filter_plate}`;
  if(pData && pData.limit && pData.page) query += `&limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;

  return query;
}